<template>
    <div>
        <mdb-card class="mb-2">
            <mdb-card-header color="default">查詢</mdb-card-header>
            <mdb-card-body class="pt-0">
                <mdb-row>
                    <mdb-col md="3">
                        <mdb-input label="姓名"
                                   v-model="search.name"></mdb-input>
                    </mdb-col>
                    <mdb-col md="3">
                        <mdb-input label="電話"
                                   v-model="search.cel"></mdb-input>
                    </mdb-col>
                    <mdb-col md="3"
                             class="pt-3 text-sm-right text-md-left">
                        <mdb-btn color="default"
                                 size="sm"
                                 class="mt-3"
                                 @click="data.nowPage=1;searchdata()">
                            <i class="fa fa-search"></i>
                            搜&nbsp;&nbsp;尋
                        </mdb-btn>
                    </mdb-col>
                </mdb-row>
            </mdb-card-body>
        </mdb-card>
        <mdb-card>
            <mdb-card-body>
                <table class="table table-striped rwd-table-dispersion">
                    <!--Table head-->
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>姓名</th>
                            <th>電話</th>
                            <th></th>
                        </tr>
                    </thead>
                    <!--Table head-->
                    <!--Table body-->
                    <tbody>
                        <tr v-for="(c,ck) in customerList"
                            :key="`c_${ck}`">
                            <th data-title="#">{{ck+1}}</th>
                            <td data-title="姓名">{{c.name}}</td>
                            <td data-title="電話">{{c.cel}}</td>
                            <td class="text-right text-md-left">
                                <mdb-btn color="primary"
                                         size="sm"
                                         @click="$router.push(`/customer/repairLog/${c.id}/${c.name}`)">
                                    <i class="fa fa-eye"></i>
                                    檢視
                                </mdb-btn>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="7"
                                scope="row"
                                v-show="customerList.length==0">無資料</td>
                        </tr>
                    </tbody>
                    <!--Table body-->
                </table>
                <page :nowPage="data.nowPage"
                      :totalPage="data.totalPage"
                      @switchpage="pg=>{search.page=pg;getData();}" />
            </mdb-card-body>
        </mdb-card>
    </div>
</template>
<script>
import {
  mdbCard,
  mdbCardHeader,
  mdbCardBody,
  mdbRow,
  mdbCol,
  mdbInput,
  mdbBtn,
} from "mdbvue";
import page from "../../components/page";
export default {
  props: { query: { default: {} } },
  components: {
    mdbCard,
    mdbCardHeader,
    mdbCardBody,
    mdbInput,
    mdbRow,
    mdbCol,
    mdbBtn,
    page,
  },
  data() {
    return {
      search: {
        name: "",
        cel: "",
        page: 1,
      },
      data: {
        nowPage: 1,
        totalPage: 0,
      },
      customerList: [],
      nowurl: "",
    };
  },
  mounted() {
    let vue = this;
    // 把篩選的資料放到變數裡面
    for (let i in vue.search) {
      vue.search[i] = vue.query[i] ? vue.query[i] : "";
    }
    vue.search.page = vue.search.page != "" ? vue.search.page : 1;
    this.getData();
  },
  computed: {},
  methods: {
    // 搜尋資料
    searchdata() {
      let vue = this;
      let url = vue.$route.path + "?";
      for (let k in vue.search) {
        url += `${k}=${vue.search[k]}&`;
      }
      if (vue.nowurl != url) {
        vue.nowurl = url;
        vue.$router.push(url);
      }
      vue.getData();
    },
    getData() {
      let vue = this;
      let query = [
        {
          name: "customer",
          data: {},
          pg: vue.search.page == "" ? 1 : vue.search.page,
        },
        {
          name: "customer",
          data: {},
          num: "",
        },
      ];
      let search = {};
      if (vue.search.name != "") {
        search["name"] = { type: "6", value: vue.search.name };
      }
      if (vue.search.cel != "") {
        search["cel"] = { type: "6", value: vue.search.cel };
      }
      query[0].data = query[1].data = search;
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `data/get_data/?query=${JSON.stringify(query)}`,
          },
        })
        .then((res) => {
          if (res.data.status == "ok") {
            // console.info(res.data);
            vue.customerList = res.data.customerList;
            vue.data.totalPage = Math.ceil(res.data.customerNumber / 10);
            vue.data.nowPage = vue.search.page;
          }
        });
      vue.$store
        .dispatch("get_form", {
          payload: {
            url: `product/get_repair_target_brand/`,
          },
        })
        .then((res) => {
          if (res.data.status == "data") {
            vue.repair_target_brandList = res.data.repair_target_brand;
          }
        });
    },
  },
};
</script>